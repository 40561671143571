<template>
    <div>
        <br>
        <h3 class="text-dark">Create New Role</h3>
        <br>
        <my-box>
            <b-button size="sm" variant="secondary" class="mr-2" squared>Advanced</b-button>
        </my-box>
        <br>
        <my-box>
            <b-form @submit="createRole">
                <b-row>
                    <b-col>
                        <b-form-group label="Name">
                            <b-button-group class="w-100">
                                <div class="prepend-color form-control form-control-sm rounded-0 border-right-0 w-auto bg-muted">@</div>
                                <b-form-input size="sm" required v-model="role.name" class="rounded-0"></b-form-input>
                            </b-button-group>
                        </b-form-group>
                    </b-col>
                </b-row>    
                <div class="text-right">
                    <b-button variant="primary" type="submit" squared size="sm">Create New Role</b-button>
                </div>
            </b-form>
        </my-box>
    </div>
</template>

<script>
export default {
    data() {
        return {
            role: {
                name: null
            }
        }
    },
    methods: {

        /**
         * Create new user data
         */
        createRole(event) {
            event.preventDefault()
            this.$store.dispatch('credentials/role/post', { data: { data: this.role }})
            .then(res => {
                this.$router.push({ path: '/credentials/roles' })
            })
            .catch(err => {
                this.errorMsg = error.status + ' - ' + error.statusText
            })
        }
    },
    created() {

    },
};
</script>   

<style scoped>
.prepend-color {
    background: #e9ecef;
}
</style>